import React from 'react'
import BelowPost from '../../components/BelowPost'
import SEO from '../../components/SEO';
import { Box } from '@chakra-ui/core';

import Layout from '../../components/Layout';
import MakeEnhance from "maker-enhance";

const Post = ({ pageContext: post }) => {
  const title = post.title.replace(/&#\d+;/gm,function(s) {
      var r = String.fromCharCode(s.match(/\d+/gm)[0]);
      return r;
  });
  return (
    <Layout>
      <SEO titleText={title} featuredImage={post.featuredImage.imageFile.childImageSharp.fluid.src} />
      <Box as="section" className="post-wrapper single-post">
        { (typeof window !== "undefined" ? <MakeEnhance user="integrations" index="1" />:"") }
      </Box>
      <BelowPost post={post} />
    </Layout>
  )
}

export default Post;
