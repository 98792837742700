import React from 'react';
import { Box } from '@chakra-ui/core';
import NextPost from './NextPost'
import "../theme/typeface-utopia-std.css"


const BelowPost = ({ post }) => {
  return (
    <Box as="section" className="related-posts" textAlign="center" maxW="100%" mt={"1.50rem"} pt={["1.25rem", 30, 30]} bg="#f0f0f0">
      <Box maxW="1366px" w="100%" display="inline-block" textAlign="left">
        <Box px={"1.25rem"} fontSize={["36px", "48px", "48px"]} color="#000">
          <div className="tk-utopia-std-headline">Related Posts</div>
        </Box>
        <Box id="post-footer-meta" py={4} display={["block", "inline-flex", "inline-flex"]} px={[5, 0, 0]}>
            <NextPost post={post} />
        </Box>
      </Box>
    </Box>
  );
};

export default BelowPost;