import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Box, PseudoBox } from '@chakra-ui/core';
import usePublisherOptions from '../../../../node_modules/@staticfuse/gatsby-theme-publisher/src/hooks/use-publisher-options';

const POSTS_QUERY = graphql`
  query GET_POSTS_2 {
    wpgraphql {
      posts(first: 10000000) {
        nodes {
          title
          id
          uri
          featuredImage {
            sourceUrl(size: AB_BLOCK_POST_GRID_SQUARE)
          }
          author {
            lastName
            firstName
          }
        }
      }
    }
  }
`;
const chooseThreePosts = (post, data) => {
  const posts = data.wpgraphql.posts.nodes;
  const currentPostIndex = posts.findIndex((item) => item.id === post.id);

  //Simple logic to get 3 posts around the current posts
  let nextNextPostIndex = currentPostIndex + 2;
  let nextPostIndex = currentPostIndex + 1;
  let previousPostIndex = currentPostIndex - 1;

  //This logic ensures we are not proceeding with invalid (out-of-range) index-
  // which could have fatal results while build
  nextNextPostIndex = (nextNextPostIndex >= posts.length ? nextNextPostIndex - posts.length : nextNextPostIndex);
  nextPostIndex = (nextPostIndex >= posts.length ? nextPostIndex - posts.length : nextPostIndex);
  previousPostIndex = (previousPostIndex < 0 ? posts.length + previousPostIndex : previousPostIndex);

  return [
    posts[previousPostIndex],
    posts[nextPostIndex],
    posts[nextNextPostIndex]
  ];
};

const NextPost = ({ post }) => {
  const { blogURI } = usePublisherOptions();
  return (
    <StaticQuery
      query={POSTS_QUERY}
      render={(data) => {
        const threePosts = chooseThreePosts(post, data);
        return threePosts.map((nextPost, i) => {
          return (
          <Box as="article" px={[0, 5]} boxSizing="border-box" w={["100%", "34%", "34%"]}>
            <PseudoBox
              role="group"
              as="div"
              align="center"
              position="relative"
            >
              {nextPost && nextPost.featuredImage && (
                <a
                  href={`${blogURI}${nextPost.uri}`}
                  title={nextPost.title}
                >
                  <Box as="div"
                    className="next-postthumbnail"
                    width='100%'
                    overflow="hidden"
                    marginRight={10}
                    height={230}
                    position="relative"
                  >
                    <PseudoBox as="img"
                      src={nextPost.featuredImage.sourceUrl}
                      alt={nextPost.title}
                      className="next-post-featured-image"
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%) scale(0.7)"
                      minW="100%"
                      minH="100%"
                      maxW="initial"
                      _hover={{transform: "translate(-50%, -50%) scale(0.75)"}}
                      transition="450ms ease"
                    />
                  </Box>
                </a>
              )}

              <Box>
                {nextPost && (
                    <a
                      href={`${blogURI}${nextPost.uri}`}
                      title={nextPost.title}
                    >
                      <PseudoBox pt={3} as="h3" mb={0} fontSize={["18px", "20px", "24px"]} lineHeight="normal" _groupHover={{textDecoration: "underline"}}>
                        <span
                          style={{color:"#000"}}
                          className="tk-utopia-std-headline"
                          dangerouslySetInnerHTML={{
                            __html: nextPost.title,
                          }}
                        />
                      </PseudoBox>
                      <Box as="p" pt={2} pb={4} fontSize={["14px", "15px", "16px"]}>
                        <Box as="span" fontFamily="Work Sans"  opacity={0.5}>By</Box>
                        <Box as="span" fontFamily="Work Sans"> {nextPost.author.firstName} {nextPost.author.lastName}</Box>
                      </Box>
                    </a>
                )}
              </Box>
            </PseudoBox>
          </Box>)
        });
      }}
    />
  );
};

export default NextPost;